import pkg from "../../package.json";

export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://crm-repuestos.azurewebsites.net/api/v1/",
  signalRUrl: "https://crm-repuestos.azurewebsites.net/hub/notification",
  googleMapskey: "AIzaSyCoF2frpi0FD7ZW0Y2NNiQ8NJ8ZqXiWoug",
  sentryDSN: "https://b94fd94f1f5cc694169ecc01794df96c@o4505172191608832.ingest.sentry.io/4506343997046784",
  appTitle: (title: string) => `${title ? `${title} | ` : ""}CRM Platino Repuestos`,
  googleAnalyticsId: "G-DB1FMRDM8T",
};
